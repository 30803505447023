// @flow

import React, { Component } from "react";
import GraphiQL from "graphiql";
import GraphiQLExplorer from "graphiql-explorer";
import { buildClientSchema, getIntrospectionQuery, parse } from "graphql";

import "graphiql/graphiql.css";
import "./App.css";

import type { GraphQLSchema } from "graphql";

function fetcher(url: string, apiKey: string, params: Object): Object {
  return fetch(
    url,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-api-key": apiKey,
      },
      body: JSON.stringify(params)
    }
  )
    .then(function(response) {
      return response.text();
    })
    .then(function(responseBody) {
      try {
        return JSON.parse(responseBody);
      } catch (e) {
        return responseBody;
      }
    });
}

type ToolbarInputProps = {
  label: string;
  value: string | null;
  disabled: boolean;
  onChange: (e) => void;
};

class ToolbarInput extends Component<ToolbarInputProps, {}> {

  render() {
    return (
      <label>
        {this.props.label}
        <input size="100" type="text" value={this.props.value} onChange={this.props.onChange} onInput={this.props.onChange} disabled={this.props.disabled}/>
      </label>
    );
  }
};

const DEFAULT_QUERY = ``;

type State = {
  schema: ?GraphQLSchema;
  query: string;
  explorerIsOpen: boolean;
  url: string;
  apiKey: string;
  locked: boolean;
};

class App extends Component<{}, State> {
  _graphiql: GraphiQL;
  state = { schema: null, query: DEFAULT_QUERY, explorerIsOpen: true, url: "", apiKey: "", locked: false };

  componentDidMount() {
    const query = new URLSearchParams(window.location.search);
    const apiUrl = query.get('apiUrl') || "";
    const apiKey = query.get('apiKey') || "";
    const locked = query.get('locked') === "true";
    const staging = query.get('staging') === "true";
    let url = apiUrl;
    if (!Boolean(url)) {
      url = staging ? 'https://v3-staging.api.zaius.com/graphql' : 'https://api.zaius.com/v3/graphql';
    }
    this.setState({url: url, apiKey: apiKey, locked: locked});
    this.refreshSchema(url, apiKey);
  }

  refreshSchema(url: string, apiKey: string) {
    fetcher(url, apiKey, {
      query: getIntrospectionQuery()
    }).then(result => {
      const editor = this._graphiql.getQueryEditor();
      editor.setOption("extraKeys", {
        ...(editor.options.extraKeys || {}),
        "Shift-Alt-LeftClick": this._handleInspectOperation
      });
      this.setState({ schema: buildClientSchema(result.data) });
    }).catch(error => this.setState({schema: null}));
  }

  _handleInspectOperation = (
    cm: any,
    mousePos: { line: Number, ch: Number }
  ) => {
    const parsedQuery = parse(this.state.query || "");

    if (!parsedQuery) {
      console.error("Couldn't parse query document");
      return null;
    }

    var token = cm.getTokenAt(mousePos);
    var start = { line: mousePos.line, ch: token.start };
    var end = { line: mousePos.line, ch: token.end };
    var relevantMousePos = {
      start: cm.indexFromPos(start),
      end: cm.indexFromPos(end)
    };

    var position = relevantMousePos;

    var def = parsedQuery.definitions.find(definition => {
      if (!definition.loc) {
        console.log("Missing location information for definition");
        return false;
      }

      const { start, end } = definition.loc;
      return start <= position.start && end >= position.end;
    });

    if (!def) {
      console.error(
        "Unable to find definition corresponding to mouse position"
      );
      return null;
    }

    var operationKind =
      def.kind === "OperationDefinition"
        ? def.operation
        : def.kind === "FragmentDefinition"
        ? "fragment"
        : "unknown";

    var operationName =
      def.kind === "OperationDefinition" && !!def.name
        ? def.name.value
        : def.kind === "FragmentDefinition" && !!def.name
        ? def.name.value
        : "unknown";

    var selector = `.graphiql-explorer-root #${operationKind}-${operationName}`;

    var el = document.querySelector(selector);
    el && el.scrollIntoView();
  };

  _handleEditQuery = (query: string): void => this.setState({ query });

  _handleToggleExplorer = () => {
    this.setState({ explorerIsOpen: !this.state.explorerIsOpen });
  };

  _handleApiKeyChange = (e) => {
    const value = e.target.value;
    this.setState({
        apiKey: value
    });
    this.refreshSchema(this.state.url, value);
  }

  render() {
    const { query, schema } = this.state;
    return (
      <div className="graphiql-container">
        <GraphiQLExplorer
          schema={schema}
          query={query}
          onEdit={this._handleEditQuery}
          onRunOperation={operationName =>
            this._graphiql.handleRunQuery(operationName)
          }
          explorerIsOpen={this.state.explorerIsOpen}
          onToggleExplorer={this._handleToggleExplorer}
        />
        <GraphiQL
          ref={ref => (this._graphiql = ref)}
          fetcher={(params) => fetcher(this.state.url, this.state.apiKey, params)}
          schema={schema}
          query={query}
          onEditQuery={this._handleEditQuery}
        >
          <GraphiQL.Toolbar>
            <GraphiQL.Button
              onClick={() => this._graphiql.handlePrettifyQuery()}
              label="Prettify"
              title="Prettify Query (Shift-Ctrl-P)"
            />
            <GraphiQL.Button
              onClick={() => this._graphiql.handleToggleHistory()}
              label="History"
              title="Show History"
            />
            <GraphiQL.Button
              onClick={this._handleToggleExplorer}
              label="Explorer"
              title="Toggle Explorer"
            />
            <ToolbarInput
              label="API Key"
              value={this.state.apiKey}
              onChange={this._handleApiKeyChange}
              disabled={this.state.locked}
            />
          </GraphiQL.Toolbar>
        </GraphiQL>
      </div>
    );
  }
}

export default App;
